<script setup lang="ts">
import { HeaderTheme } from '@/types';

/********************
 * COMPOSITIONS      *
 ********************/
const appStore = useAppStore();
const getRouteBaseName = useRouteBaseName();
const route = useRoute();

/********************
 * REFS & VARS       *
 ********************/
const routeBaseName = computed(() => {
  return getRouteBaseName(route);
});

const pageClass = computed(() => {
  const snailCaseClass = routeBaseName.value?.split(/(?=[A-Z])/) || [''];
  return `page page--${snailCaseClass.join('-').toLowerCase()}`;
});

function onSetNavigation(value: boolean) {
  appStore.sidebarOpen = value;
}
</script>

<template>
  <div class="flex h-svh max-h-screen flex-col">
    <CiSidebar
      class="block lg:hidden"
      :open="appStore.sidebarOpen"
      @close="onSetNavigation(false)"
    />

    <CiHeader
      :theme="HeaderTheme.black"
      @set-navigation="onSetNavigation"
    />

    <main
      class="relative grow"
      :class="pageClass"
    >
      <slot />
    </main>

    <CiOverlay
      v-if="appStore.sidebarOpen"
      @click="onSetNavigation(false)"
    />
  </div>
</template>

<style></style>
